.MainPage {
    background: black;
    position: absolute;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    color: white;
    overflow-x: hidden;
}

.MainPage__Container {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: auto;
    box-sizing: border-box;
    max-width: 1200px;
    justify-content: space-between;
    height: 100%;
    padding: 65px;
}

.NavBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.NavBar a {
    color: rgba(255, 255, 255, 0.78);
    font-weight: 300;
    font-style: normal;
    text-decoration: none;
    font-size: 20px;
}

.PageText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.PageText h1 {
    font-size: 48px;
    line-height: 150%;
    color: rgba(255, 255, 255, 0.94);
    margin: 0;
}

.PageText p {
    font-size: 20px;
    line-height: 150%;
    color: rgba(255, 255, 255, 0.78);
    max-width: 600px;
    margin: 0;
    margin-top: 50px;
}

.BackgronudVideo {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    top: 0;
}

.BackgroundVideo__PLayer {
    position: relative;
    align-self: center;
    margin: auto;
    height: 100%;
    max-height: 800px;
    transition: all 1s ease-out;
}

.BottomText {
    color: rgba(255, 255, 255, 0.78);
}


@media only screen and (max-width: 1000px) {
    .PageText h1 {
        font-size: 32px;
    }
    .PageText p, .BottomText, .NavBar a {
        font-size: 16px;
    }
}

@media only screen and (max-width: 600px) {
    .MainPage__Container {
        padding: 50px 30px;
    }

    .NavBar a {
        white-space: nowrap;
        line-height: 150%;
    }

    .NavBar {
        flex-wrap: wrap;
        row-gap: 10px;
        column-gap: 30px;
    }

    .BackgroundVideo__PLayer {
        width: 100%;
        height: auto;
        display: none;
    }

    .BackgronudVideo {
        background-image: url('background.png');
        background-position: center;
    }
}
